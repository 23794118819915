import React from 'react';
import styles from './BasicPageStructure.module.css';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

const BasicPageStructure = ({ content, link = '/', namePage }) => {
    return (
        <div className={styles.screen}>
            <div className={styles.TopArea}>
                <div className={styles.right}>
                    <Link to={link} className={styles.backLink}>
                        <IoIosArrowBack />
                    </Link>
                </div>
                <div className={styles.center}>{namePage}</div>
                <div className={styles.left} />
            </div>
            <div className={styles.listPage}>
                {content}
            </div>
        </div>
    );
};

export default BasicPageStructure;
