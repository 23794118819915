import React, { useEffect, useState } from 'react';
import BasicPageStructure from '../../BasicPageStructure';
import styles from './CalendarioComponent.module.css';
import apiAxios from '../../../api/apiAxios';
import useTenantId from '../../../pages/tenantId';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CalendarioViewComponent from './view/CalendarioViewComponent';

const CalendarioComponent = () => {
    const [date, setDate] = useState(new Date()); // Dia atual por padrão
    const [isViewOpen, setIsViewOpen] = useState(true); // Por padrão, já está aberto
    const [calendario, setCalendario] = useState([]);
    const [categorias, setCategorias] = useState([]);

    const tenantId = useTenantId();

    const fetchCategorias = async () => {
        try {
            const response = await apiAxios.get(`/api/calendar/category/${tenantId}`);
            setCategorias(response.data);
        } catch (error) {
            console.error('Erro ao buscar categorias:', error);
        }
    };

    useEffect(() => {
        if (tenantId) {
            fetchCategorias();
        }
    }, [tenantId]);

    const fetchCalendario = async () => {
        try {
            const response = await apiAxios.get(`/api/calendar/all/${tenantId}`);
            setCalendario(response.data);
        } catch (error) {
            console.error('Erro ao buscar calendários:', error);
        }
    };

    useEffect(() => {
        fetchCalendario();
    }, [tenantId]);

    const handleDateChange = (selectedDate) => {
        setDate(selectedDate); // Define a data para buscar eventos de um dia específico
    };

    const handleMonthChange = async ({ activeStartDate }) => {
        const startOfMonth = moment(activeStartDate).startOf('month').format("YYYY-MM-DD");
        const endOfMonth = moment(activeStartDate).endOf('month').format("YYYY-MM-DD");
    
        try {
            const response = await apiAxios.get(`/api/calendar/all/${tenantId}`, {
                params: { startDate: startOfMonth, endDate: endOfMonth },
            });
    
            setCalendario((prev) => {
                // Filtrar eventos já existentes no estado
                const newEvents = response.data.filter(
                    (newEvent) =>
                        !prev.some(
                            (existingEvent) => existingEvent.id === newEvent.id
                        )
                );
                return [...prev, ...newEvents]; // Adicionar apenas eventos novos
            });
        } catch (error) {
            console.error("Erro ao buscar eventos do mês:", error);
        }
    };
    

    const handleViewToggle = (selectedDate) => {
        const eventDate = moment(selectedDate).format("YYYY-MM-DD");
        const events = calendario.filter(event => moment(event.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD") === eventDate);

        if (events.length > 0) {
            setDate(selectedDate);
            setIsViewOpen(true);
        } else {
            setIsViewOpen(false);
        }
    };

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const eventDate = moment(date).format("YYYY-MM-DD");
            const events = calendario.filter(event => 
                moment(event.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD") === eventDate
            );
    
            if (events.length > 0) {
                return (
                    <div className={styles.eventDay}>
                        {events.map((event, index) => (
                            <div
                                key={index}
                                style={{
                                    backgroundColor: event.categoriaColor,
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    marginLeft: '5px',
                                }}
                            ></div>
                        ))}
                    </div>
                );
            }
        }
        return null;
    };
    

    const dashboardContent = (
        <div className={styles.all}>
            <div className={styles.bottomAreaItem}>
                <div className={styles.calendar}>
                    <div className={styles.calendarWrapper}>
                        <Calendar
                            onChange={handleDateChange}
                            value={date}
                            onClickDay={handleViewToggle}
                            onActiveStartDateChange={handleMonthChange}
                            prev2Label={null}
                            next2Label={null}
                            tileContent={tileContent}
                            className={styles.reactCalendar}
                            locale="pt-BR"
                            formatMonthYear={(locale, date) =>
                                date.toLocaleDateString(locale, { month: 'long', year: 'numeric' })
                                    .replace(/^\w/, (c) => c.toUpperCase())
                            }
                        />
                    </div>
                    {isViewOpen && (
                        <CalendarioViewComponent
                            date={date}
                            events={calendario.filter(event =>
                                moment(event.date, "DD/MM/YYYY HH:mm:ss").format("YYYY-MM-DD") ===
                                moment(date).format("YYYY-MM-DD")
                            )}
                            onClose={() => setIsViewOpen(false)}
                        />
                    )}

                </div>
                <div className={styles.categories}>
                    <h2>Categorias</h2>
                    {categorias.map((categoria) => (
                        <div className={styles.item} key={categoria.id}>
                            <div className={styles.colorBoll} style={{ background: categoria.category.color }}></div>
                            <div className={styles.nameCat}>{categoria.category.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        <BasicPageStructure
            content={dashboardContent}
            link="/"
            namePage="Calendário"
        />
    );
};

export default CalendarioComponent;
