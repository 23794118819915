import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import apiAxios from '../../../../api/apiAxios';
import useTenantId from '../../../../pages/tenantId';
import styles from './CalendarioViewComponent.module.css';
import { IoClose } from "react-icons/io5";

import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { BsCalendarWeek } from "react-icons/bs";

const CalendarioViewComponent = ({ date, onClose }) => {
    const [calendario, setCalendario] = useState([]);
    const tenantId = useTenantId();
    const contentRef = useRef(null);

    const fetchCalendario = async (selectedDate) => {
        try {
            let url = '/api/calendar/';
            const params = { tenantId };

            if (selectedDate) {
                // Buscar eventos do dia específico
                const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
                url += `by-date/${formattedDate}`;
            } else {
                // Buscar eventos do mês inteiro
                const startOfMonth = moment().startOf('month').format("YYYY-MM-DD");
                const endOfMonth = moment().endOf('month').format("YYYY-MM-DD");
                url += `by-month`;
                params.startDate = startOfMonth;
                params.endDate = endOfMonth;
            }

            const response = await apiAxios.get(url, { params });
            setCalendario(response.data);
        } catch (error) {
            console.error('Erro ao buscar calendário:', error);
        }
    };

    useEffect(() => {
        if (date) {
            fetchCalendario(date); // Busca eventos do dia específico
        } else {
            fetchCalendario(); // Busca eventos do mês inteiro
        }
    }, [date]);


    const handleClose = (e) => {
        if (!contentRef.current.contains(e.target)) {
            onClose();
        }
    };

    const convertDescriptionToHTML = (descricao) => {
        if (!descricao) return '';
        try {
            const contentState = convertFromRaw(JSON.parse(descricao));
            return stateToHTML(contentState);
        } catch (error) {
            console.error('Erro ao converter descrição:', error);
            return 'Descrição inválida';
        }
    };

    const adjustColor = (hexColor, amount) => {
        let color = hexColor.replace('#', '');
        if (color.length === 3) {
            color = color.split('').map(char => char + char).join('');
        }

        const num = parseInt(color, 16);
        const r = (num >> 16) & 0xFF;
        const g = (num >> 8) & 0xFF;
        const b = num & 0xFF;

        // Soma dos valores RGB para avaliar a luminosidade
        const totalBrightness = r + g + b;

        // Verifica se a cor é muito escura (próxima do preto)
        if (totalBrightness < 60) {
            // Clarear a cor
            return `rgb(${Math.min(255, r + amount)}, ${Math.min(255, g + amount)}, ${Math.min(255, b + amount)})`;
        } else {
            // Escurecer a cor
            return `rgb(${Math.max(0, r - amount)}, ${Math.max(0, g - amount)}, ${Math.max(0, b - amount)})`;
        }
    };

    function formatDate(dateString) {
        console.log(dateString);

        // Ajusta o formato da data para ISO, caso necessário
        const [datePart, timePart] = dateString.split(' '); // Divide entre data e hora
        const [d, m, y] = datePart.split('/'); // Renomeia variáveis para evitar conflito
        const isoDateString = `${y}-${m}-${d}T${timePart}`; // Formato ISO

        const daysOfWeek = [
            "domingo",
            "segunda-feira",
            "terça-feira",
            "quarta-feira",
            "quinta-feira",
            "sexta-feira",
            "sábado",
        ];
        const months = [
            "janeiro",
            "fevereiro",
            "março",
            "abril",
            "maio",
            "junho",
            "julho",
            "agosto",
            "setembro",
            "outubro",
            "novembro",
            "dezembro",
        ];

        const date = new Date(isoDateString); // Cria o objeto Date com o formato ISO
        const dayNumber = date.getDate(); // Renomeia para evitar conflito
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const dayOfWeek = daysOfWeek[date.getDay()];

        console.log(`dia ${dayNumber} de ${month} de ${year}, ${dayOfWeek}`);
        return `Dia ${dayNumber} de ${month} de ${year}, ${dayOfWeek}`;
    }


    return (
        <div className={styles.allScreen} onClick={handleClose}>
            <div ref={contentRef} className={`${styles.content} ${styles.slideIn}`}>
                {calendario.map(event => (
                    <React.Fragment key={event._id}>
                        <div className={styles.categoryColor} style={{ background: event.categoriaColor, color: adjustColor(event.categoriaColor, 130) }}>
                            <BsCalendarWeek />
                        </div>
                        <div className={styles.conteudo}>
                            <p className={styles.category}>{event.categoriaName}</p>
                            <h2>
                                {event.Titulo}
                            </h2>
                            <p className={styles.date} style={{ fontSize: "14px", color: "gray" }}>
                                {formatDate(event.date)}
                            </p>
                            <div className={styles.descricao}>
                                <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHTML(event.descricao) }} />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default CalendarioViewComponent;
