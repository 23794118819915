import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { useSelector } from 'react-redux';
import NotFoundPage from './pages/NotFoundPage';
import HomeAluno from './pages/Aluno/HomeAluno';
import HomeResponsavel from './pages/Responsavel/HomeResponsavel';
import AtividadesAluno from './pages/Aluno/AtividadesAluno';
import ResponseAtividadeAluno from './pages/Aluno/ResponseAtividadeAluno';
import InformesAluno from './pages/Aluno/InformesAluno.js/InformesAluno';
import MenuAluno from './pages/Aluno/MenuAluno/MenuAluno';
import CardapioAluno from './pages/Aluno/Cardapio/CardapioAluno';
import Recuperacao from './pages/RecuperaçãoDeSenha/Recuperacao';
import AgendaAluno from './pages/Aluno/agenda/AgendaAluno';
import Mensagens from './pages/Aluno/mensagens/Mensagens';

import BoletinsAluno from './pages/Aluno/BoletinsAluno';
import BoletinsResponsavel from './pages/Responsavel/BoletinsResponsavel';
import MenuResponsavel from './pages/Responsavel/MenuResponsavel';
import AgendaResponsavel from './pages/Responsavel/AgendaResponsavel';
import InformesResponsavel from './pages/Responsavel/InformesResponsavel';
import CalendarioResponsavel from './pages/Responsavel/CalendarioResponsavel';
import CardapioResponsavel from './pages/Responsavel/CardapioResponsavel';
import PerfilResponsavel from './pages/Responsavel/PerfilResponsavel';

function App() {
  const isAuthenticated = useSelector(state => state.user.userInfo?.token);
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = isAuthenticated && JSON.parse(atob(userInfo.token.split('.')[1]));
  console.log(tokenPayload)
  const isAluno = tokenPayload?.isAluno;
  const isResponsavel = tokenPayload?.isResponsavel;

  return (
    <Router>
      <Routes>
        {isAuthenticated && (isAluno || isResponsavel) ? (
          <>
            {
              isAluno ? (
                <>
                  <Route path='/' element={<HomeAluno />} />
                  <Route path='/atividades' element={<AtividadesAluno />} />
                  <Route path='/atividades/:AtvId' element={<ResponseAtividadeAluno />} />
                  <Route path='/informes' element={<InformesAluno />} />
                  <Route path='/menu' element={<MenuAluno />} />
                  <Route path='/cardapio' element={<CardapioAluno />} />
                  <Route path='/agenda' element={<AgendaAluno />} />
                  <Route path='/mensagens' element={<Mensagens />} />
                  <Route path='/boletins' element={<BoletinsAluno />} />
                </>
              ) : (
                <Route path='/*' element={<NotFoundPage />} />
              )
            }

            {
              isResponsavel ? (
                <>
                  <Route path='/' element={<HomeResponsavel />} />
                  <Route path='/menu' element={<MenuResponsavel />} />
                  <Route path='/agenda' element={<AgendaResponsavel />} />
                  <Route path='/informes' element={<InformesResponsavel />} />
                  <Route path='/boletins' element={<BoletinsResponsavel />} />
                  <Route path='/calendario' element={<CalendarioResponsavel />} />
                  <Route path='/cardapio' element={<CardapioResponsavel />} />
                  <Route path='/perfil' element={<PerfilResponsavel />} />
                </>
              ) : (
                <Route path='/*' element={<NotFoundPage />} />
              )
            }

          </>
        ) : (
          <>
            <Route path='/' element={<LoginPage />} />
            <Route path='/reset-password/:token' element={<Recuperacao />} />
          </>
        )}
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
