import React, { useState, useEffect } from 'react';
import styles from './CardapioComponent.module.css';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Loading from '../../../pages/Loading';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';

import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import tinycolor from 'tinycolor2';
import BasicPageStructure from '../../BasicPageStructure';

const getTextColor = (bgColor) => {
    const color = tinycolor(bgColor);
    return color.isLight() ? '#000000' : '#FFFFFF';
};

const CardapioComponent = () => {
    const [loading, setLoading] = useState(true);
    const [cardapios, setCardapios] = useState([]);
    const [error, setError] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const daysOfWeek = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira'];

    const userInfo = useSelector(state => state.user.userInfo);
    const tenantId = JSON.parse(atob(userInfo.token.split('.')[1])).tenantId;

    useEffect(() => {
        const fetchCardapios = async () => {
            try {
                const [cardapiosRes, itensRes] = await Promise.all([
                    apiAxios.get(`/api/card/${tenantId}`),
                    apiAxios.get(`/api/card/item/items/${tenantId}`)
                ]);

                const itensMap = itensRes.data.reduce((acc, item) => {
                    acc[item._id] = item.group?.Color || '#007bff';
                    return acc;
                }, {});

                const cardapiosComCor = cardapiosRes.data.map(cardapio => {
                    if (cardapio.alimentacao) {
                        cardapio.alimentacao.forEach(alimentacao => {
                            if (alimentacao.conteudo) {
                                alimentacao.conteudo.forEach(conteudo => {
                                    if (conteudo.itemCardapioDetails) {
                                        conteudo.itemCardapioDetails.forEach(item => {
                                            item.categoryColor = itensMap[item._id] || '#007bff';
                                        });
                                    }
                                });
                            }
                        });
                    }
                    return cardapio;
                });

                setCardapios(cardapiosComCor);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCardapios();
    }, [tenantId]);

    const handleMonthChange = (monthIndex) => {
        setSelectedMonth(monthIndex);
    };

    const filteredCardapios = cardapios.filter((cardapio) => {
        const cardapioDate = new Date(cardapio.dateFormatted || cardapio.date);
        return cardapioDate.getMonth() === selectedMonth;
    });

    const renderObservacao = (observacao) => {
        try {
            const contentState = convertFromRaw(JSON.parse(observacao));
            const editorState = EditorState.createWithContent(contentState);
            return { __html: stateToHTML(editorState.getCurrentContent()) };
        } catch (error) {
            console.error("Erro ao renderizar observação:", error);
            return { __html: "Observação indisponível" };
        }
    };

    const dashboardContent = (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.screen}>
                    <div className={styles.monthsContainer}>
                        {months.map((month, index) => (
                            <button
                                key={index}
                                className={`${styles.monthButton} ${selectedMonth === index ? styles.selected : ''}`}
                                onClick={() => handleMonthChange(index)}
                            >
                                {month}
                            </button>
                        ))}
                    </div>

                    <div className={styles.listCardapio}>
                        {error ? (
                            <p>Ocorreu um erro: {error}</p>
                        ) : filteredCardapios.length === 0 ? (
                            <p>Nenhum cardápio encontrado para este mês.</p>
                        ) : (
                            filteredCardapios.map((cardapio) => (
                                <div className={styles.table_all} key={cardapio._id}>
                                    <h2>
                                        {cardapio.Titulo} (
                                        &nbsp;
                                        {
                                            new Date(
                                                new Date(cardapio.dateFormatted || cardapio.date).getTime() +
                                                new Date().getTimezoneOffset() * 60000
                                            ).toLocaleDateString()
                                        }
                                        &nbsp;
                                        à
                                        &nbsp;
                                        {
                                            new Date(
                                                new Date(cardapio.dateFormattedEnd || cardapio.date).getTime() +
                                                new Date().getTimezoneOffset() * 60000
                                            ).toLocaleDateString()
                                        }
                                        &nbsp;
                                        )
                                    </h2>
                                    <div className={styles.tableOverflow}>
                                        <table className={styles.table}>
                                            <thead>
                                                <tr>
                                                    <th>Refeição</th>
                                                    {daysOfWeek.map((day, index) => (
                                                        <th key={index}>{day}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cardapio.alimentacao.map((alimentacao, index) => (
                                                    <tr key={index}>
                                                        <td className={styles.refeicao}>{alimentacao.refeicao}</td>
                                                        {daysOfWeek.map((day, dayIndex) => {
                                                            const conteudo = alimentacao.conteudo.find(c => c.diaDaSemana === day);
                                                            return (
                                                                <td key={dayIndex}>
                                                                    {conteudo && conteudo.itemCardapioDetails && conteudo.itemCardapioDetails.length > 0 ? (
                                                                        <ul className={styles.itemList}>
                                                                            {conteudo.itemCardapioDetails.map((item, itemIndex) => (
                                                                                <li
                                                                                    key={itemIndex}
                                                                                    style={{
                                                                                        backgroundColor: item.categoryColor || '#ccc',
                                                                                        color: getTextColor(item.categoryColor || '#ccc'),
                                                                                        padding: '5px 10px',
                                                                                        borderRadius: '5px'
                                                                                    }}
                                                                                    className={styles.itemListItem}
                                                                                >
                                                                                    <strong>{item.titulo || "Nome Indisponível"}</strong>
                                                                                    {item.descricao && (
                                                                                        <span style={{ display: 'block', fontSize: '0.9em', marginTop: '5px' }}>
                                                                                            {item.descricao}
                                                                                        </span>
                                                                                    )}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    ) : 'N/A'}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {cardapio.observacao && (
                                        <div
                                            className={styles.observacao}
                                            dangerouslySetInnerHTML={renderObservacao(cardapio.observacao)}
                                        />
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </>
    );

    return (
        <BasicPageStructure
            content={dashboardContent}
            link="/"
            namePage="Cardápio"
        />
    );
};

export default CardapioComponent;
