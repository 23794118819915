import React, { useEffect, useState } from 'react';
import styles from './AtividadesAluno.module.css';
import { IoIosArrowBack } from "react-icons/io";
import { Link } from 'react-router-dom';
import { BiSolidShapes } from "react-icons/bi";
import { useSelector } from 'react-redux';
import { getAllAtividades } from '../../actions/AtividadesActions';
import { getResponsesByTenant } from '../../actions/ResponseActions';
import apiAxios from '../../api/apiAxios';
import { FaPlay } from "react-icons/fa";
import Loading from '../Loading';
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";

const AtividadesAluno = () => {
    const [atividadesParaFazer, setAtividadesParaFazer] = useState([]);
    const [respostasRealizadas, setRespostasRealizadas] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Para Fazer");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedAtividade, setSelectedAtividade] = useState(null);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const tenantId = tokenPayload.tenantId;
    const userId = tokenPayload._id;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await apiAxios.get(`/api/user/users/${tenantId}`);
                const users = usersResponse?.data?.users || [];
        
                const aluno = users.find(user => user._id === userId);
                if (!aluno) {
                    console.warn("Aluno não encontrado."); // Log para debugging
                    setAtividadesParaFazer([]);
                    setRespostasRealizadas([]);
                    return;
                }
        
                const { serie, turno } = aluno.alunoInfo || {};
                if (!serie || !turno) {
                    console.warn("Dados de série ou turno não encontrados."); // Log para debugging
                    setAtividadesParaFazer([]);
                    setRespostasRealizadas([]);
                    return;
                }
        
                const atividadesData = await getAllAtividades(tenantId);
                const respostas = await getResponsesByTenant(tenantId);
        
                const paraFazer = atividadesData.filter(
                    atividade => atividade.serie === serie &&
                    atividade.turno === turno &&
                    !respostas.some(resposta => resposta.atividadeId === atividade._id && resposta.userid === userId)
                );
        
                const realizadas = respostas.filter(resposta =>
                    atividadesData.some(atividade => atividade._id === resposta.atividadeId) &&
                    resposta.userid === userId
                );
        
                setAtividadesParaFazer(paraFazer);
                setRespostasRealizadas(realizadas);
            } catch (error) {
                console.error('Erro ao obter os dados:', error.message);
            } finally {
                setLoading(false);
            }
        };
        

        fetchData();
    }, [userId, tenantId]);

    const openPopup = (atividade) => {
        setSelectedAtividade(atividade);
        setShowPopup(true);
    };

    const closePopup = () => {
        setSelectedAtividade(null);
        setShowPopup(false);
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleClickOutsidePopup = (event) => {
        const className = event.target.className.baseVal || event.target.className;  // baseVal para SVG, className para HTML
        if (typeof className === 'string' && className.includes(styles.ConfirmStartAtv)) {
            closePopup();
        }
    };
    

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Erro ao carregar atividades: {error.message}</div>;
    }

    return (
        <div className={styles.atividadesScreen}>
            <div className={styles.top}>
                <Link to="/" className={styles.backLink}>
                    <IoIosArrowBack />
                </Link>
                <p className={styles.title}>Atividades</p>
                <div className={styles.empty}></div>
            </div>

            <div className={styles.content}>
                <div className={styles.choseList}>
                    <div
                        style={{ borderBottom: selectedTab === "Para Fazer" ? "2px solid #ffde59" : "none" }}
                        onClick={() => handleTabClick("Para Fazer")}
                    >
                        Para Fazer
                    </div>
                    <div
                        style={{ borderBottom: selectedTab === "Realizadas" ? "2px solid #ffde59" : "none" }}
                        onClick={() => handleTabClick("Realizadas")}
                    >
                        Realizadas
                    </div>
                </div>
                <div className={styles.all}>
    {selectedTab === "Para Fazer" && atividadesParaFazer.length === 0 && (
        <div className={styles.emptyState}>
            <p>Você não tem atividades para fazer no momento.</p>
        </div>
    )}
    {selectedTab === "Para Fazer" && atividadesParaFazer.map((atividade) => (
        <div className={styles.item} key={atividade._id}>
            <div className={styles.sec1}>
                <BiSolidShapes />
            </div>
            <div className={styles.sec2}>
                <div className={styles.tit}>{atividade.titulo}</div>
                <div className={styles.mat}>{atividade.materia} - {atividade.questoes.length} Questões</div>
            </div>
            <div className={styles.sec3}>
                <Link onClick={() => openPopup(atividade)} className={styles.play}>
                    <FaPlay style={{ marginLeft: "2px" }} />
                </Link>
            </div>
        </div>
    ))}
    {selectedTab === "Realizadas" && respostasRealizadas.length === 0 && (
        <div className={styles.emptyState}>
            <p>Você ainda não concluiu nenhuma atividade.</p>
        </div>
    )}
    {selectedTab === "Realizadas" && respostasRealizadas.map((resposta) => (
        <div className={styles.item} key={resposta._id} style={{ cursor: "pointer" }}>
            <div className={styles.sec1}>
                <BiSolidShapes />
            </div>
            <div className={styles.sec2}>
                <div className={styles.tit}>{resposta.Titulo}</div>
                <div className={styles.mat}>{resposta.materia} - {resposta.questoes.length} Questões</div>
            </div>
            <div className={styles.sec3}>
                <div className={styles.nota}>
                    Nota: <p>{resposta.nota}</p>
                </div>
            </div>
        </div>
    ))}
</div>

            </div>

            {showPopup && selectedAtividade && (
                <div className={styles.ConfirmStartAtv} onClick={handleClickOutsidePopup}>
                    <div className={styles.popUpConfirmAtv}>
                        <div className={styles.contentClose} >
                            <IoIosClose style={{ cursor: "pointer" }} onClick={closePopup} />
                        </div>
                        <IoAlertCircleOutline className={styles.alert} />
                        <hr />
                        <div className={styles.contentConfirm}>
                            <div className={styles.title}>Tem certeza que deseja iniciar a atividade?</div>
                            <div className={styles.description}>
                                Quando iniciar será dado um tempo específico para a conclusão.
                                Então antes de começar certifique-se que está em um lugar silencioso e propenso à realização da avaliação.
                            </div>
                            <div className={styles.action}>
                                <Link style={{background:"rgb(252, 210, 25)"}} to={`/atividades/${selectedAtividade._id}`} onClick={closePopup}>Iniciar avaliação</Link>
                                <Link onClick={closePopup}>Tentar mais tarde</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AtividadesAluno;
