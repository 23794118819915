import React, { useEffect, useState } from 'react'
import Loading from '../../../pages/Loading';
import { getTenants } from '../../../actions/UserActions';
import { useSelector } from 'react-redux';
import styles from './MenuComponent.module.css'
import { GoHome, GoHomeFill } from 'react-icons/go';
import { CiCalendar } from "react-icons/ci";
import { FaBell, FaCamera, FaRegBell } from 'react-icons/fa6';
import { TfiAgenda, TfiLayoutGrid2, TfiLayoutGrid2Alt } from 'react-icons/tfi';
import { MdModeComment, MdOutlineModeComment } from 'react-icons/md';
import { RiEdit2Line, RiMegaphoneLine } from 'react-icons/ri';
import { PiAppleLogoBold, PiExam } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';
import BasicStructure from '../../../components/BasicStructure';
import img from '../../../assets/Logos/Logotipo escola municipal minimalista azul e laranja.png';
import img2 from '../../../assets/Logos/Cópia de Logotipo escola municipal minimalista azul e laranja.png'
import apiAxios from '../../../api/apiAxios';
import imgProfile from "../../../assets/profile/user.png";
import { FaEdit } from 'react-icons/fa';


const MenuComponent = ({ access }) => {
    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState();
    const [tenants, setTenants] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await apiAxios.get(`/api/user/user/${tokenPayload._id}`);
                setUser(response.data.user);
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };
        if (tokenPayload && tokenPayload._id) {
            fetchUser();
        }
    }, [tokenPayload]);

    const getItemsPageDeitado = () => [
        {
            icon: <GoHome />,
            iconSelected: <GoHomeFill />,
            link: "/",
            isSelected: false
        },
        {
            icon: <FaRegBell />,
            iconSelected: <FaBell />,
            link: "/notificacoes",
            isSelected: false
        },
        {
            icon: <MdOutlineModeComment />,
            iconSelected: <MdModeComment />,
            link: "/mensagens",
            isSelected: false
        },
        {
            icon: <TfiLayoutGrid2 />,
            iconSelected: <TfiLayoutGrid2Alt />,
            link: "/menu",
            isSelected: true
        },
    ];

    const getItemsPageEmPe = () => [
        {
            icon: <GoHome />,
            iconSelected: <GoHomeFill />,
            link: "/",
            isSelected: false
        },
        {
            icon: <FaRegBell />,
            iconSelected: <FaBell />,
            link: "/notificacoes",
            isSelected: false
        },
        {
            icon: <MdOutlineModeComment />,
            iconSelected: <MdModeComment />,
            link: "/mensagens",
            isSelected: false
        },
        {
            icon: <TfiLayoutGrid2 />,
            iconSelected: <TfiLayoutGrid2Alt />,
            link: "/menu",
            isSelected: true
        }
    ];

    const itemsPageDeitado = getItemsPageDeitado();
    const itemsPageEmPe = getItemsPageEmPe();

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            setImagesLoaded(true);
        };
        image.src = img
    }, []);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
                setLoading(false); // Dados carregados, atualiza o estado de carregamento
            } catch (error) {
                console.error('Error fetching tenants:', error);
                setLoading(false); // Em caso de erro, atualiza o estado de carregamento
            }
        };
        fetchTenants();
    }, []);

    const Functions = [
        {
            id: 2,
            title: "Agenda",
            icon: <TfiAgenda />,
            link: "/agenda"
        },
        {
            id: 4,
            title: "Informes",
            icon: <RiMegaphoneLine />,
            link: "/informes"
        },
        access === 'responsavel' ?
            {
                id: 6,
                title: "Calendário",
                icon: <CiCalendar />,
                link: "/calendario"
            }
            :
            {

            },
        {
            id: 1,
            title: "Atividades",
            icon: <RiEdit2Line />,
            link: "/menu"
        },

        {
            id: 3,
            title: "Cardápio",
            icon: <PiAppleLogoBold />,
            link: "/cardapio"
        },
        {
            id: 5,
            title: "Boletins",
            icon: <PiExam />,
            link: "/menu"
        },
    ]

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants();
                const foundTenant = response.find(tenant => tenant._id === tokenPayload.tenantId);
                if (foundTenant) {
                    setTenants([foundTenant]); // Define tenants como um array com o tenant encontrado
                } else {
                    setTenants([]); // Define tenants como uma array vazia se nenhum tenant for encontrado
                }
            } catch (error) {
                console.error('Error fetching tenants:', error);
            }
        };
        fetchTenants();
    }, []);

    const ContentPage = (
        <>
            <div className={styles.perfil}>
                <div className={styles.imageContainer}>
                    <img src={user?.img ? user?.img : imgProfile} alt="profile" />
                    <div className={styles.cameraIcon}>
                        <FaCamera />
                    </div>
                </div>
                <div className={styles.details}>
                    <p className={styles.name}>{`${user?.firstName} ${user?.lastName}`}</p>
                    <FaEdit className={styles.editIcon} onClick={() => navigate("/perfil")}/>
                </div>
                <p className={styles.role} style={{textTransform:"capitalize"}}>{access}</p>
            </div>
            <div className={styles.listFunctions}>
                {Functions.map((item) => (
                    <Link key={item.id} to={item.link}>
                        <div className={styles.icon}>{item.icon}</div>
                        {item.title}
                    </Link>
                ))}
            </div>
        </>
    )

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className={styles.screen}>
                    <BasicStructure itemsPageDeitado={itemsPageDeitado} itemsPageEmPe={itemsPageEmPe} ContentPage={ContentPage} />
                </div>
            )}
        </>
    );
}

export default MenuComponent