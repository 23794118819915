import React, { useEffect, useState } from 'react'
import AgendaComponet from '../../components/PagesComponents/Agenda/AgendaComponent'
import apiAxios from '../../api/apiAxios';
import { useSelector } from 'react-redux';

const AgendaResponsavel = () => {

    const userInfo = useSelector(state => state.user.userInfo);
    const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
    const tenantId = tokenPayload.tenantId;
    const userId = tokenPayload._id;
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serie,setSerie] = useState();
    const [turno,setTurno] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await apiAxios.get(`/api/user/users/${tenantId}`);
                const users = usersResponse.data.users;

                const aluno = users.find(user => user._id === userId);
                if (!aluno) {
                    throw new Error("O aluno específico não foi encontrado.");
                }

                // if (!aluno.alunoInfo || !aluno.alunoInfo.serie || !aluno.alunoInfo.turno) {
                //     throw new Error("Dados de série ou turno do aluno não encontrados.");
                // }

                const serie = aluno?.responsavelInfo.map((a)=>(a.serie));
                const turno = aluno?.responsavelInfo.map((a)=>(a.turno));
                setSerie(serie);
                setTurno(turno)

                const eventsResponse = await apiAxios.get('/api/agenda/' + tenantId);
                const allEvents = eventsResponse.data;

                const filteredEvents = allEvents.filter(event => event.serie === serie);
                setEvents(filteredEvents);

                setLoading(false);
            } catch (error) {
                console.error('Erro ao obter as atividades:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userId, tenantId]);


    return (
        <AgendaComponet serieOptions={serie} turnoOptions={turno}/>
    )
}

export default AgendaResponsavel