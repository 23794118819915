import React, { useEffect, useState } from 'react';
import styles from './AgendaAluno.module.css';
import Loading from '../../Loading';
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import apiAxios from '../../../api/apiAxios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import AgendaViewComponent from '../../../components/PagesComponents/Agenda/AgendaViewComponent';
import useTenantId from '../../tenantId';

const AgendaAluno = () => {
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
  const tenantId = tokenPayload.tenantId;
  const userId = tokenPayload._id;
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [hasEvents, setHasEvents] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await apiAxios.get(`/api/user/users/${tenantId}`);
        const users = usersResponse.data.users;

        const aluno = users.find(user => user._id === userId);
        if (!aluno) {
          throw new Error("O aluno específico não foi encontrado.");
        }

        if (!aluno.alunoInfo || !aluno.alunoInfo.serie || !aluno.alunoInfo.turno) {
          throw new Error("Dados de série ou turno do aluno não encontrados.");
        }

        const serie = aluno.alunoInfo.serie;
        const turno = aluno.alunoInfo.turno;

        const eventsResponse = await apiAxios.get('/api/agenda/' + tenantId);
        const allEvents = eventsResponse.data;

        const filteredEvents = allEvents.filter(event => event.serie === serie);
        setEvents(filteredEvents);

        setLoading(false);
      } catch (error) {
        console.error('Erro ao obter as atividades:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, tenantId]);

  const handleDateClick = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    console.log('Selected Date:', formattedDate);

    const eventExists = events.some(event => {
      const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).toISOString().split('T')[0];
      console.log('Event Date:', eventDate);
      return eventDate === formattedDate;
    });

    if (eventExists) {
      setSelectedDate(date);
      setHasEvents(true);
    } else {
      setSelectedDate(null);
      setHasEvents(false);
    }
  };

  const handleClose = () => {
    setSelectedDate(null);
    setHasEvents(false);
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const highlightDates = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];
      const event = events.find(event => {
        const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6'));
        if (isValidDate(eventDate)) {
          const eventDateString = eventDate.toISOString().split('T')[0];
          return eventDateString === dateString;
        }
        return false;
      });
  
      if (event) {
        const color = event.tipo === 'prova' ? '#ffff00' : '#EC3237'; // Altere conforme o tipo do evento
        return <div className={styles.eventMarker} style={{ backgroundColor: color }}></div>;
      }
    }
    return null;
  };
  

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.screen}>
          <div className={styles.TopArea}>
            <div className={styles.right}>
              <Link to="/" className={styles.backLink}>
                <IoIosArrowBack />
              </Link>
            </div>
            <div className={styles.center}>
              Agenda
            </div>
            <div className={styles.left} />
          </div>
          <div className={styles.listAgenda}>
            <div className={styles.calendarWrapper}>
              <Calendar
                onClickDay={handleDateClick}
                prev2Label={null}
                next2Label={null}
                tileContent={highlightDates}
                className={styles.reactCalendar}
                prevLabel={<span>◀</span>}
                nextLabel={<span>▶</span>}
              />

            </div>
          </div>
        </div>
      )}
      {selectedDate && hasEvents && (
        <AgendaViewComponent
          date={selectedDate}
          events={events.filter(event => {
            const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).toISOString().split('T')[0];
            return eventDate === selectedDate.toISOString().split('T')[0];
          })}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default AgendaAluno;
