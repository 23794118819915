import React, { useEffect, useState } from 'react';
import styles from './AgendaComponent.module.css';
import Loading from '../../../pages/Loading';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import AgendaViewComponent from './AgendaViewComponent';
import useTenantId from '../../../pages/tenantId';
import BasicPageStructure from '../../BasicPageStructure';

const AgendaComponent = ({ serieOptions = [], turnoOptions = [] }) => {
    const [selectedSeries, setSelectedSeries] = useState('');
    const [selectedTurnos, setSelectedTurnos] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [hasEvents, setHasEvents] = useState(false);
    const [loading, setLoading] = useState(true);
    const tenantId = useTenantId();

    const uniqueTurnoOptions = [...new Set(turnoOptions)];

    useEffect(() => {
        if (!selectedSeries && serieOptions.length > 0) {
            setSelectedSeries(serieOptions[0]);
        }
        if (!selectedTurnos && uniqueTurnoOptions.length > 0) {
            setSelectedTurnos(uniqueTurnoOptions[0]);
        }
    }, [serieOptions, uniqueTurnoOptions]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await apiAxios.get('/api/agenda/' + tenantId);
                setEvents(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao obter os eventos:', error);
                setLoading(false);
            }
        };

        fetchEvents();
    }, [tenantId]);

    const handleSerieChange = (e) => {
        setSelectedSeries(e.target.value);
    };

    const handleTurnoChange = (e) => {
        setSelectedTurnos(e.target.value);
    };

    const highlightDates = ({ date, view }) => {
        if (view === 'month') {
            const dateString = date.toISOString().split('T')[0];
            const event = events.find(event => {
                const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6'));
                return eventDate.toISOString().split('T')[0] === dateString &&
                    event.serie === selectedSeries &&
                    event.turno === selectedTurnos;
            });

            if (event) {
                const color = event.agendastatus ? '#FFFF00' : '#EC3237'; // Amarelo para true, vermelho para false
                return <div className={styles.eventMarker} style={{ backgroundColor: color }}></div>;
            }
        }
        return null;
    };

    const handleDateClick = (date) => {
        const formattedDate = date.toISOString().split('T')[0];

        const event = events.find(event => {
            const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).toISOString().split('T')[0];
            return eventDate === formattedDate &&
                event.serie === selectedSeries &&
                event.turno === selectedTurnos;
        });

        if (event) {
            if (event.agendastatus) {
                setSelectedDate(date);
                setHasEvents(true);
            } else {
                console.log('Evento com agendastatus false, modal não será aberto');
                setSelectedDate(null);
                setHasEvents(false);
            }
        } else {
            console.log('Nenhum evento correspondente encontrado');
            setSelectedDate(null);
            setHasEvents(false);
        }
    };

    const handleClose = () => {
        setSelectedDate(null);
        setHasEvents(false);
    };

    const dashboardContent = (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                    <div className={styles.filters}>
                        <div className={styles.filterGroup}>
                            <label htmlFor="serieSelect">Séries:</label>
                            <select
                                id="serieSelect"
                                value={selectedSeries}
                                onChange={handleSerieChange}
                            >
                                {serieOptions.map((serie, index) => (
                                    <option key={index} value={serie}>{serie}</option>
                                ))}
                            </select>
                        </div>

                        <div className={styles.filterGroup}>
                            <label htmlFor="turnoSelect">Turnos:</label>
                            <select
                                id="turnoSelect"
                                value={selectedTurnos}
                                onChange={handleTurnoChange}
                            >
                                {uniqueTurnoOptions.map((turno, index) => (
                                    <option key={index} value={turno}>{turno}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={styles.listAgenda}>
                        <div className={styles.calendarWrapper}>
                            <Calendar
                                locale="pt-BR"
                                onClickDay={handleDateClick}
                                prev2Label={null}
                                next2Label={null}
                                tileContent={highlightDates}
                                className={styles.reactCalendar}
                                prevLabel={<span>◀</span>}
                                nextLabel={<span>▶</span>}
                            />
                        </div>
                    </div>

                    <div className={styles.categories}>
                        <h2>Categorias</h2>
                        <div className={styles.category}>
                            <span style={{ borderLeft: '3px solid #c72a2f' }}>Não Liberado para Alunos</span>
                        </div>
                        <div className={styles.category}>
                            <span style={{ borderLeft: '3px solid #FFDE59' }}>Liberado para Alunos</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

    return (
        <>
            <BasicPageStructure
                content={dashboardContent}
                link="/"
                namePage="Agenda"
            />
            {selectedDate && hasEvents && (
                <AgendaViewComponent
                    date={selectedDate}
                    events={events.filter(event => {
                        const eventDate = new Date(event.date.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).toISOString().split('T')[0];
                        return eventDate === selectedDate.toISOString().split('T')[0] && event.serie === selectedSeries && event.turno === selectedTurnos;
                    })}
                    onClose={handleClose}
                />
            )}
        </>
    );
};

export default AgendaComponent;
