import React, { useState, useEffect } from 'react';
import BasicPageStructure from '../../BasicPageStructure';
import apiAxios from '../../../api/apiAxios';

const BoletinsComponent = ({ serie, turno }) => {
    const [boletins, setBoletins] = useState([]); // Estado para armazenar os dados do boletim

    // Função para buscar os boletins da API
    useEffect(() => {
        const fetchBoletins = async () => {
            try {
                const response = await apiAxios.get('/boletins', {
                    params: { serie, turno },
                });
                setBoletins(response.data);
            } catch (error) {
                console.error('Erro ao buscar boletins:', error);
            }
        };

        fetchBoletins();
    }, [serie, turno]);

    return (
        <BasicPageStructure
            content={
                <div>
                    <h2>Boletim Escolar</h2>
                    {boletins.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Disciplina</th>
                                    <th>Nota</th>
                                    <th>Faltas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {boletins.map((boletim, index) => (
                                    <tr key={index}>
                                        <td>{boletim.disciplina}</td>
                                        <td>{boletim.nota}</td>
                                        <td>{boletim.faltas}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>Nenhum boletim disponível.</p>
                    )}
                </div>
            }
            link="/"
            namePage="Boletins"
        />
    );
};

export default BoletinsComponent;
